* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Style for your 3D viewer container */
.my3DViewer {
  width: 100%;
  height: 100%; /* Adjust height as needed */
  position: relative;
}

/* Ensuring the canvas fills the container */
.my3DViewer canvas {
  width: 100%;
  height: 100%;
  display: block;
}

.button-change {
  position: absolute;
  top: 0;
  left: 0;
}
